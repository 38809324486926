<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container-no-tarbar">
      <div class="public-login-logo-box margin-bottom-10 margin-top-10">
        <img class="logo" alt="调研说" src="../assets/logo.svg" />
      </div>

      <div
        class="public-areas public-area-user-list public-login-form margin-bottom-10 margin-top-10"
      >
        <div class="weui-cells margin-top-0">
          <div class="weui-cell" href="javascript:;">
            <div class="weui-cell__hd">
              <label class="weui-label">用户名</label>
            </div>
            <div class="weui-cell__bd">
              <p>
                <input
                  class="weui-input number-input"
                  id="username"
                  type="number"
                  maxlength="11"
                  placeholder="请输入手机号"
                  v-model="mobile"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="public-areas public-area-user-list public-login-form margin-bottom-10 margin-top-10"
      >
        <div class="weui-cells margin-top-0">
          <div class="weui-cell" href="javascript:;">
            <div class="weui-cell__hd">
              <label class="weui-label">密码</label>
            </div>
            <div class="weui-cell__bd">
              <p>
                <input
                  class="weui-input"
                  id="password"
                  type="password"
                  maxlength="20"
                  placeholder="请输入密码"
                  v-model="password"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="public-area-submit-box">
        <div class="weui-cells margin-top-0">
          <div class="weui-cell">
            <div class="weui-cell__bd">
              <a href="javascript:;" @click="willLogin" class="weui-btn weui-btn_primary public-btn-login"
                >登&nbsp;&nbsp;录</a
              >
            </div>
          </div>
          <div class="weui-cell">
            <div class="weui-cell__bd">
              <a @click="showNotice" v-if="!isWx" class="public-login-forget">忘记密码？</a>
            </div>
            <div class="weui-cell__ft public-register-statement1">
              <a @click="goRegister" class="public-login-register">注册</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sha256 from 'sha256'
import { isInWeiXin } from '../utils/index'
import { mobileLogin } from '../api/index'
import { getParams } from '../utils/index'

export default {
  name: "login",
  data () {
    return {
      mobile: '',
      password: '',
      inviterCode: '', // 邀请人代码
      fromName: '', // 上个页面的名称
      isWx: isInWeiXin()
    }
  },
  beforeRouteEnter (to, from, next) {
    console.log('from', from)
    next((vm) => {
      vm.fromName = from.name
    })
  },
  created () {
    this.urlParamsInit()
    localStorage.removeItem('SURVEY_token')
  },
  mounted () {
  },
  methods: {
    willLogin () {
      if ( !/^1\d{10}$/.test(this.mobile) ) {
        this.$toast.info('请输入正确的手机号码')
        return false
      }
      if (this.password.length < 6) {
        this.$toast.info('请输入正确的密码')
        return false
      }
      this.doMobileLogin()
    },
    // 登录
    async doMobileLogin () {
      const pwd = sha256(this.password)
      let params = {
        param: {
          mobile: this.mobile,
          password: pwd,
          inviterCode: this.inviterCode
        }
      }
      if (!this.inviterCode) {
        delete params.param.inviterCode
      }
      let res = {}
      try {
        res = await mobileLogin(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          this.$toast.info('登录成功')
          const token = res.result.token
          localStorage.setItem('SURVEY_token', token)
          setTimeout(() => {
            if (getParams('returnUrl')) {
              const url = getParams('returnUrl')
              location.href = url
            } else {
              this.$router.push({ name: 'home' })
            }
          })
        } else if (res.code === 'BIZ_1005') {
          this.$toast.info('该手机号尚未注册，请先注册。')
        } else {
          this.$toast.hide()
          this.$toast.info(res.message)
        }
      } catch (err) {
        this.$toast.hide()
        this.$toast.info('活动火爆，请稍后重试')
        console.log('err:', err)
      }
    },
    // 对URL上的参数进行初始化
    urlParamsInit () {
      this.inviterCode = getParams('inviterCode')
    },
    showNotice () {
      this.goResetPwd()
      // this.$dialog.show({
      //   title: '提示',
      //   content: '请在微信内搜索“每日调查任务”公众号，进行密码修改。',
      //   cancelText: '我知道了',
      //   okText: "马上重置",
      //   onOk: () => {
      //     this.goResetPwd()
      //   }
      // })
    },
    // 去重置密码
    goResetPwd () {
      console.log('goResetPwd')
      this.$router.push({name: 'resetPwd'})
    },
    // 去注册
    goRegister () {
      // if (this.fromName === 'register' && window.history.length > 1) {
      //   this.$router.back()
      // } else {
      //   const returnUrl = getParams('returnUrl')
      //   const inviterCode = this.inviterCode
      //   let query = {}
      //   if (returnUrl) {
      //     query = Object.assign(query, {returnUrl})
      //   }
      //   if (inviterCode) {
      //     query = Object.assign(query, {inviterCode})
      //   }
      //   console.log('query:', query)
      //   this.$router.push({name: 'register', query})
      // }
      const returnUrl = getParams('returnUrl')
      const inviterCode = this.inviterCode
      let query = {}
      if (returnUrl) {
        query = Object.assign(query, {returnUrl})
      }
      if (inviterCode) {
        query = Object.assign(query, {inviterCode})
      }
      console.log('query:', query)
      this.$router.push({name: 'register', query})
    }
  },
};
</script>
<style lang="css" scoped>
.public-container-no-tarbar{
  margin: unset;
}
.weui-btn.public-btn-login{
  padding: unset;
}
</style>
